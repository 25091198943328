<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Edit Camp Profile</h1>
    </page-header>
    <page-body class>
      <h1>
        {{ translations.tcEditCampProfile }}
        <div class="change-link">{{ translations.tcNew }}</div>
      </h1>
      <section class="section-1 bg-block mb-4">
        <div class="body">
          <header class="toggle-header mb-3">
            <h2 class="font-style-1">{{ translations.tcAddress }}</h2>
            <ToggleArrow @click="Section1 = !Section1" section="Section1" :status="Section1"></ToggleArrow>
          </header>
          <div v-if="Section1">
            <b-form>
              <div class="row">
                <div class="col-8">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcAddressLine1 }} *</label>
                    </div>
                    <div class="body">
                      <b-form-input
                        name="other"
                        v-model="campProfile.address.address_line1"
                        class="w-100"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-8">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcAddressLine2 }}</label>
                    </div>
                    <div class="body">
                      <b-form-input
                        name="other"
                        v-model="campProfile.address.address_line2"
                        class="w-100"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcCity }} *</label>
                    </div>
                    <div class="mb-3">
                      <b-form-input
                        id="city"
                        v-model="campProfile.address.city"
                        class="flex-0"
                        type="text"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcState }} *</label>
                    </div>
                    <div class="mb-3">
                      <b-form-select
                        :title="translations.tcChurchState"
                        v-if="!nonUS"
                        v-model="state_key"
                        :options="optionsStates"
                        @change="handleStateChange($event)"
                        class="g-select flex-0 mr-3 mb-3"
                        required
                      ></b-form-select>
                      <b-form-input
                        v-if="nonUS"
                        :title="translations.tcChurchState"
                        id="state-input"
                        :placeholder="translations.tcState"
                        v-model="campProfile.address.state"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcPostalCodeOrZipCode }} *</label>
                    </div>
                    <div class="mb-3">
                      <b-form-input
                        id="postal_code"
                        v-model="campProfile.address.postal_code"
                        class="flex-0"
                        type="text"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-8">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcSelectCountry }} * </label>
                    </div>
                    <div class="g-row mb-0 mb-sm-3">
                      <div class="g-col-1 d-flex mb-3 mb-sm-0">
                        <b-form-select
                          :title="translations.tcChurchCountry"
                          v-model="campProfile.address.cnt_key"
                          :options="optionsCountries"
                          class="g-select flex-0 mr-3 mb-3"
                          required
                        >
                        </b-form-select>
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </section>
      <section class="section-1 bg-block mb-4">
        <div class="body">
          <header class="toggle-header mb-3">
            <h2 class="font-style-1">{{ translations.tcCampDetails }}</h2>
            <ToggleArrow @click="Section2 = !Section2" section="Section2" :status="Section2"></ToggleArrow>
          </header>
          <div v-if="Section2">
            <b-form>
              <div class="row">
                <div class="col-8">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcEmail }} *</label>
                    </div>
                    <div class="mb-3">
                      <b-form-input
                        id="email_address"
                        v-model="campProfile.email.email_address"
                        class="flex-0"
                        type="text"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcPhone }}</label>
                    </div>
                    <div class="mb-3">
                      <b-form-input
                        id="phone"
                        v-model="campProfile.phone.phone_number"
                        class="flex-0"
                        type="text"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-2">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcExtension }}</label>
                    </div>
                    <div class="mb-3">
                      <b-form-input
                        id="phone_extension"
                        v-model="campProfile.phone.extension"
                        class="flex-0"
                        type="number"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcFax }}</label>
                    </div>
                    <div class="mb-3">
                      <b-form-input
                        id="fax_number"
                        v-model="campProfile.fax.fax_number"
                        class="flex-0"
                        type="text"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-2">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcExtension }}</label>
                    </div>
                    <div class="mb-3">
                      <b-form-input
                        id="fax_number_extension"
                        v-model="campProfile.fax.extension"
                        class="flex-0"
                        type="number"
                      ></b-form-input>
                    </div>
                  </b-form-group>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
              {{ translations.tcSave }}
            </b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
              {{ translations.tcCancel }}
            </b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch @selectionMade="applyFoundPerson($event)"></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'edit-camp-profile-cm',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      Section1: true,
      Section2: true,
      Section3: false,
      campProfile: {},
      findButton: '',
      selectChurch: [],
      searchedContact: [],
      state_key: '',
      statusKey: null,
      number1: 0,
    }
  },
  methods: {
    ...mapActions({
      editCampProfile: 'campModule/editCampProfile',
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      retrieveFacilityTypes: 'card/retrieveFacilityTypes',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    handleStateChange(evt) {
      this.campProfile.address.state = this.optionsStates.find((ch) => ch.value === evt).code
    },
    handleTypeChange(evt) {
      let name = this.facilityTypes
        .find((type) => type.value == evt)
        .display_text.replace(' ', '_')
        .toLowerCase()
      this.facility.facility_type_name = name
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    validateDetails() {
      return !!this.campProfile.email && !!this.campProfile.email.email_address
    },
    validateAddress() {
      if (
        this.campProfile.address.cnt_key === '' ||
        this.campProfile.address.state === '' ||
        this.campProfile.address.city === '' ||
        this.campProfile.address.postal_code === '' ||
        this.campProfile.address.address_line1 === ''
      ) {
        return false
      }
      return true
    },
    handleSaveClick() {
      if (!this.validateAddress()) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcAllFieldsRequired,
          confirmButtonText: this.translations.tcOk,
        })
        return
      }
      if (!this.validateDetails()) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorEmailFieldEmpty,
          confirmButtonText: this.translations.tcOk,
        })
        return
      }

      let data = {
        ind_key: this.userId,
        fax: this.getFaxData(),
        email: this.getEmailData(),
        phone: this.getPhoneData(),
        address: this.getAddressData(),
        organization: this.campProfile.organization,
      }

      this.submitRequest(data)
    },
    getFaxData() {
      let fax = {
        fax_key: this.campProfile.fax.fax_key,
        fax_number: this.campProfile.fax.fax_number,
        fax_cnt_key: this.campProfile.fax.fax_cnt_key,
        fax_delete_flag: this.campProfile.fax.fax_number && this.campProfile.fax.fax_number.length > 0 ? false : true,
      }
      let entity = {
        fxe_key: this.campProfile.fax.fxe_key,
        fxe_extension: this.campProfile.fax.extension,
        fxe_fax_key: this.campProfile.fax.fax_key,
        fxe_fxt_key: this.campProfile.fax.fxt_key,
        fxe_entity_key: this.userOrgKey,
        primary_fax_flag: true,
      }
      return {
        fax: fax,
        entity: entity,
      }
    },
    getPhoneData() {
      let phone = {
        phn_key: this.campProfile.phone.phn_key,
        phn_number: this.campProfile.phone.phone_number,
        phn_cnt_key: this.campProfile.phone.phn_cnt_key,
        phn_delete_flag:
          this.campProfile.phone.phone_number && this.campProfile.phone.phone_number.length > 0 ? false : true,
      }
      let entity = {
        pxe_key: this.campProfile.phone.pxe_key,
        pxe_extension: this.campProfile.phone.extension,
        pxe_phn_key: this.campProfile.phone.phn_key,
        pxe_pht_key: this.campProfile.phone.pht_key,
        pxe_entity_key: this.userOrgKey,
        primary_fax_flag: true,
      }
      return {
        phone: phone,
        entity: entity,
      }
    },
    getEmailData() {
      let email = {
        eml_key: this.campProfile.email.eml_key,
        eml_emt_key: this.campProfile.email.eml_emt_key,
        eml_entity_key: this.userOrgKey,
        eml_address: this.campProfile.email.email_address,
      }
      return email
    },
    getAddressData() {
      let address = {
        adr_key: this.campProfile.address.adr_key,
        adr_line1: this.campProfile.address.address_line1,
        adr_line2: this.campProfile.address.address_line2,
        adr_line3: this.campProfile.address.address_line3,
        adr_city: this.campProfile.address.city,
        adr_state: this.campProfile.address.state,
        adr_postal_code: this.campProfile.address.postal_code,
        adr_cnt_key: this.campProfile.address.cnt_key,
      }
      return address
    },
    async submitRequest(data) {
      try {
        this.setLoadingStatus(true)
        let response = await this.editCampProfile(data)
        this.setLoadingStatus(false)
        if (response) {
          this.$swal({
            icon: 'success',
            text: this.translations.tcSuccess,
            confirmButtonText: this.translations.tcOk,
          })
          this.$router.push({ name: 'camp-profile-cm' })
        } else {
          throw 'bad response'
        }
      } catch (e) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorEditingProfile,
          confirmButtonText: this.translations.tcOk,
        })
      }
    },
    getFacility() {},
    handleCancelClick() {
      this.$router.push({ name: 'camp-profile-cm' })
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.campProfile = this.formattedProfile
      if (!this.campProfile) {
        this.$router.push({ name: 'camp-profile-cm' })
      }
      await Promise.all([
        await this.retrieveFacilityTypes(),
        await this.getViewTranslations(),
        await this.loadAddressFormOptions(),
      ]).then(() => {
        this.state_key = this.campProfile.address.sta_key
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      facilityTypes: 'card/getFacilityTypes',
      formattedProfile: 'campModule/formattedProfile',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userOrgKey: 'user/userCampKey',
    }),
    optionsStates() {
      return [
        { value: null, text: this.translations.tcSelectState, disabled: false },
        ...this.convertedAddressFormOptions.states_long,
      ]
    },
    optionsCountries() {
      return [
        { value: null, text: this.translations.tcSelectCountry, disabled: false },
        ...this.convertedAddressFormOptions.countries,
      ]
    },
    optionsFacilityTypes() {
      let data = [{ value: null, text: 'FacilityTypes', disabled: true }]
      let types = this.facilityTypes.map((type) => {
        return { value: type.value, text: type.display_text }
      })
      return types
    },
    nonUS() {
      return this.campProfile.address.cnt_key !== 'bc4b70f8-280e-4bb0-b935-9f728c50e183'
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
